export default [
  {
    key: "imageId",
    initKey: "image",
    label: "field.image",
    type: "async-single-image",
    path: "gallery",
    pw: 1900,
    ph: 1271,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,

  },
  {
    key: "galleryCategoryId",
    label: "field.galleryCategory",
    rules: "required",
    type: "asyn-single-selection",
    repository: "galleryCategory",
    selectionKey: "id",
    selectionLabel: "nameEn",
  },
  {
    key: "title",
    label: "field.title",
    rules: "required|max:100",
    type: "text"
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    type: "text"
  },
  {
    cols: 6,
    skip: true,
    hide: true,
  },
  {
    key: "isEnable",
    label: "field.active",
    type: "checkbox",
    cols: "auto",
  },

]
